import axios from 'axios'

const httpClient = axios.create()

let refreshTokenPromise = null

// Add a request interceptor
httpClient.interceptors.request.use(
  config => {
    config.headers['X-Key-Inflection'] = 'camel'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
httpClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (!refreshTokenPromise) {
        refreshTokenPromise = new Promise((resolve, reject) => {
          // Emit an event or call a function to handle token refresh
          window.dispatchEvent(
            new CustomEvent('tokenRefreshNeeded', {
              detail: { resolve, reject },
            })
          )
        }).finally(() => {
          refreshTokenPromise = null
        })
      }

      try {
        await refreshTokenPromise
        return httpClient(originalRequest)
      } catch (refreshError) {
        // Handle refresh token error (e.g., emit event for logout)
        window.dispatchEvent(new CustomEvent('authenticationFailed'))
        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  }
)

export default httpClient
