import { useStorage } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

import httpClient from '@/api/http-client'

export function useAuth(router) {
  const cookies = useCookies()
  const error = ref(null)
  const token = ref(cookies.get('auth_token'))

  const logout = async () => {
    localStorage.removeItem('websocketUrl')
    // Redirect to the login page
    window.location.href = '/auth/logout'
  }

  const authRefreshing = useStorage('authRefreshing', false)

  const refreshToken = async () => {
    localStorage.setItem('authRefreshing', true)
    try {
      const { data } = await httpClient.post('/auth/refresh')
      token.value = data.token
      //cookies.set('auth_token', data.token)
    } catch (error) {
      error.value = error
    } finally {
      // Emit an event to signal that the token refresh is done
      // This event is used to resolve the promise in the interceptor
      // and continue the original request
      window.dispatchEvent(new CustomEvent('tokenRefreshed'))
    }
  }

  return { token, error, logout, refreshToken }
}
