import { computed, reactive, toRefs, watch } from 'vue'

import api from '@/api/system-api'

export default function useSystem({ loadImmediately = true } = {}) {
  const state = reactive({
    system: {},
    status: '',
    loading: false,
    error: null,
  })

  const errored = computed(() => !!state.error)

  async function fetch() {
    state.loading = true
    try {
      const response = await api.fetch()
      state.system = response.data.data
      localStorage.setItem('websocketUrl', response.data?.meta?.websocketUrl)
      state.status = response.status
      state.errored = false
    } catch (error) {
      state.status = error.response.status
      state.error = error
      state.errored = true
    } finally {
      state.loading = false
    }
  }

  watch(
    () => {},
    () => fetch(),
    { immediate: loadImmediately }
  )
  return {
    ...toRefs(state),
    errored,
    fetch,
  }
}
