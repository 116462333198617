import httpClient from '@/api/http-client'

const BASE_URL = '/api/v1'

const systemsAPI = {
  /**
   * fetchAll.
   *
   * @param {} filter
   * @param {} order
   * @param {} page
   */
  fetchAll(filter = {}, order = {}, page = 1) {
    return httpClient.get(`${BASE_URL}/system`)
  },

  /**
   * fetch.
   */
  fetch() {
    return httpClient.get(`${BASE_URL}/system/`)
  },
}

export default systemsAPI
