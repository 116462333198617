<template lang="pug">
main(class="slide-fade")
  .reauthing(v-if="authRefreshing == true")
    .loading.loading-ring.loading-lg
  Navigation.h-24(v-if="hasToken")
  section#page-container(:class="{'mdi': hasMdi}")
    router-view( v-slot="{ Component }")
      component(:is="Component")
</template>

<script setup>
import { useStorage } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'
import { computed, onMounted, onUnmounted, ref, unref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Navigation from '@/components/Navigation.vue'
import { useAuth } from '@/use/auth'
import useSystem from '@/use/system'

const router = useRouter()
const cookies = useCookies()
const authRefreshing = useStorage('authRefreshing')

const { refreshToken } = useAuth()
const token = ref(cookies.get('auth_token'))
const { loading, system, fetch, status, error } = useSystem({ loadImmediately: false })
const hasToken = computed(() => {
  return !!token.value && token.value !== 'undefined' && token.value !== ''
})

const handleLoginRedirect = () => {
  router.push('/login')
}

watch(
  hasToken,
  () => {
    if (!hasToken.value) {
      handleLoginRedirect()
    }
  },
  { immediate: true }
)

// watch the cookie for changes
watch(
  () => cookies.get('auth_token'),
  value => {
    // if the value is null, empty string or undefined, redirect to login
    if (!value || value === 'undefined' || value === '') {
      token.value = null
      handleLoginRedirect()
    }
  },
  { immediate: true }
)
const route = useRoute()

const hasMdi = computed(() => {
  return route.matched.some(
    record => record.path === '/campaigns/:campaignId/conversation/:id'
  )
})

onMounted(() => {
  if (hasToken.value) {
    fetch() // get the system data for the websocket url
  } else {
    handleLoginRedirect()
  }

  window.addEventListener('tokenRefreshNeeded', async event => {
    try {
      if (unref(authRefreshing)) {
        const newToken = await refreshToken()
        localStorage.setItem('authRefreshing', false)
      }
      event.detail.resolve()
    } catch (error) {
      event.detail.reject(error)
      localStorage.setItem('authRefreshing', false)
    }
  })

  window.addEventListener('authenticationFailed', () => {
    console.log('authentication failed')
  })
})

onUnmounted(() => {
  window.removeEventListener('tokenRefreshNeeded')
  window.removeEventListener('authenticationFailed')
})
</script>

<style lang="scss">
@import '@/scss/nice.scss';
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
}
.reauthing {
  @apply w-full min-h-screen flex items-center justify-center;
}
.mdi {
  @apply lg:flex lg:flex-row-reverse lg:flex-nowrap lg:columns-2;
}
</style>
