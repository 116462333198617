<template lang="pug">
.navbar.bg-base-100.shadow-lg.sticky.top-0.z-50
  .navbar-start
    .dropdown
      .btn.btn-ghost(class="lg:hidden" tabindex="0" role="button" @click="handleMobileMenuOpen")
        Logs(size="24")
      ul.dropdown-content.left-0.menu.mt-5.bg-base-100.rounded-box.p-2.shadow.shadow-neutral-content.w-52(class="z-[1]" ref="mobileDropdown")
        li(v-if="!loading && system['allowedToAdmin?']")
          router-link(
            tag="li"
            active-class="shadow-sm btn-candy font-bold"
            :to="{name: 'TenantAgents'}"
          )
            FontAwesomeIcon.size-4(icon="fa-solid fa-user-secret")
            | Agents
        li(v-if="!loading")
          router-link(
            active-class="shadow-sm btn-candy font-bold"
            :class="{ 'shadow-sm btn-candy font-bold': $route.name === 'CampaignDetailsView' }"
            :to="{name: 'Campaigns'}"
          )
            FontAwesomeIcon.size-4(icon="fa-solid fa-bullseye")
            | Campaigns
        li(v-if="!loading")
          router-link(
            @click="resetAnalytics"
            :class="{ 'shadow-sm btn-candy font-bold': analyticsRoutes }"
            :to="{name: 'Analytics'}"
          )
            ChartNoAxesCombined.info-icon.mb-1.size-4
            | Analytics
        li(v-if="!loading && system['allowedToSuperAdmin?']")
          router-link(
            active-class="shadow-sm btn-candy font-bold"
            :to="{name: 'Tenants'}"
          )
            TenantIcon.info-icon.mb-1.size-6
            span Tenants
    .ml-8.grid.grid-cols-2.grid-rows-1.items-center(class="lg:flex lg:flex-col lg:justify-center")
      router-link( v-if="!loading" to="/" )
        Logo.size-16
          div(
            class="ml-4"
          )
      router-link(v-if="!loading" to="/" )
        Wordmark.w-16.ml-1.fill-white(class="lg:mt-1 lg:ml-0")
  .navbar-center.hidden(class="lg:flex")
    Steps
  .navbar-end
    .dropdown.dropdown-hover
      .avatar(role="button")
        .rounded-full.bg-primary.p-4
          UserCog(size="24")
      ul.dropdown-content.menu.bg-base-100.rounded-box.w-52.p-2.shadow(class="z-[1]")
        li
          a(@click="logout") Logout
</template>
<script setup>
import { onClickOutside } from '@vueuse/core'
import { Logs, UserCog } from 'lucide-vue-next'
import { ChartNoAxesCombined } from 'lucide-vue-next'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import Steps from '@/components/Steps.vue'
import Logo from '@/images/Hyperyou.svg'
import TenantIcon from '@/images/tenant.svg'
import Wordmark from '@/images/wordmark-rw.svg'
import { useUIStore } from '@/stores/ui'
import { useAuth } from '@/use/auth'
import useSystem from '@/use/system'

const router = useRouter()
const { logout } = useAuth(router)
const { loading, system, fetch } = useSystem({})
const settingsDropdown = ref(null)
const mobileDropdown = ref(null)

const ui = useUIStore()
const { mobileMenuOpen } = storeToRefs(ui)
const { openMobileMenu, closeMobileMenu } = ui

const closeDropdown = event => {
  if (!settingsDropdown.value) return
  if (!settingsDropdown.value.contains(event.target)) {
    settingsDropdown.value.open = false
  }
}

const handleMobileMenuOpen = () => {
  openMobileMenu()
}

onMounted(() => {
  document.addEventListener('click', closeDropdown)
})

onUnmounted(() => {
  document.removeEventListener('click', closeDropdown)
})

onClickOutside(settingsDropdown, closeDropdown)
onClickOutside(mobileDropdown, () => {
  closeMobileMenu()
})

const analyticsRoutes = computed(() => {
  return (
    router.currentRoute.value.path === '/analytics' ||
    router.currentRoute.value.path === '/'
  )
})
const resetAnalytics = () => {
  if (analyticsRoutes.value) {
    router.go()
  }
}
</script>

<style lang="scss">
@import '@/scss/nice.scss';
.themable {
  @apply fill-accent;
}
summary.dropdown-toggle::marker {
  display: none;
  content: '';
}
ul.dropdown-content {
  @apply right-0;
}
.navbar {
  z-index: 1000;
}
</style>
