// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>
// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
//
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar as farCalendar,
  faComments as farComments,
  faFileLines as farFileLines,
  faRectangleList as farRectangleList,
  faTrashCan as farTrashCan,
} from '@fortawesome/free-regular-svg-icons'
import {
  faArrowRight,
  faBars,
  faBook,
  faBullseye,
  faCalendar,
  faChartBar,
  faCheck,
  faCircleInfo,
  faClock,
  faCog,
  faFlaskVial,
  faForward,
  faGears,
  faHashtag,
  faInbox,
  faMicrophone,
  faMicrophoneSlash,
  faPaperPlane,
  faPause,
  faPeopleArrows,
  faPlay,
  faPuzzlePiece,
  faQuoteRight,
  faRightFromBracket,
  faRobot,
  faSpinner,
  faStop,
  faSync,
  faTableList,
  faTrashCan,
  faUpRightFromSquare,
  faUser,
  faUserGroup,
  faUserSecret,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from '@/App.vue'
import '@/css/style.css'
import router from '@/router'

library.add(
  faBars,
  faBook,
  faBullseye,
  faCalendar,
  faChartBar,
  faCheck,
  faCircleInfo,
  faClock,
  faCog,
  faFlaskVial,
  faForward,
  faTrashCan,
  faHashtag,
  faInbox,
  faQuoteRight,
  faMicrophone,
  faArrowRight,
  faMicrophoneSlash,
  faPaperPlane,
  faPeopleArrows,
  faPlay,
  faPause,
  faPuzzlePiece,
  farCalendar,
  farComments,
  farFileLines,
  farTrashCan,
  faRightFromBracket,
  faUpRightFromSquare,
  faRobot,
  farRectangleList,
  faGears,
  faSpinner,
  faStop,
  faSync,
  faTableList,
  faUser,
  faUserGroup,
  faUserSecret,
  faXmark
)

const pinia = createPinia()

createApp(App)
  .use(router)
  .use(pinia)
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .mount('#app')
