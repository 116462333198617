<template lang="pug">
.flex-none
  div(tabindex="0" role="button" class="btn btn-ghost lg:hidden" @click="toggleMenu" @keydown.enter="toggleMenu")
    svg(
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor")
      path(
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 12h8m-8 6h16")
  ul.menu.menu-md.bg-base-200.rounded-box.px-1(
    ref="mainMenu"
    class="md:menu-lg lg:menu-horizontal"
    )
    li.mr-1(v-if="!loading && system['allowedToAdmin?']")
      router-link(
        tag="li"
        active-class="shadow-sm btn-candy font-bold"
        :to="{name: 'TenantAgents'}"
      )
        FontAwesomeIcon(icon="fa-solid fa-user-secret")
        | Agents
    li.mr-1(v-if="!loading")
      router-link(
        active-class="shadow-sm btn-candy font-bold"
        :class="{ 'shadow-sm btn-candy font-bold': $route.name === 'CampaignDetailsView' }"
        :to="{name: 'Campaigns'}"
      )
        FontAwesomeIcon(icon="fa-solid fa-bullseye")
        | Campaigns
    li.mr-1(v-if="!loading")
      router-link(
        active-class="shadow-sm btn-candy font-bold"
        @click="resetAnalytics"
        :class="{ 'shadow-sm btn-candy font-bold': analyticsRoutes }"
        :to="{name: 'Analytics'}"
      )
        ChartNoAxesCombined.info-icon.mb-1.w-6.h-6
        | Analytics


    li.mr-1(v-if="!loading && system['allowedToSuperAdmin?']")
      router-link(
        active-class="shadow-sm btn-candy font-bold"
        :to="{name: 'Tenants'}"
      )
        TenantIcon.info-icon.mb-1.w-6.h-6
        span Tenants
</template>
<script setup>
import { ChartNoAxesCombined } from 'lucide-vue-next'
import { computed, ref, unref } from 'vue'
import { useRouter } from 'vue-router'

import TenantIcon from '@/images/tenant.svg'
import useSystem from '@/use/system'

const mainMenu = ref(null)
const router = useRouter()
const { loading, system, fetch } = useSystem({})
const toggleMenu = () => {
  unref(mainMenu).classList.toggle('hidden')
}

const analyticsRoutes = computed(() => {
  return (
    router.currentRoute.value.path === '/analytics' ||
    router.currentRoute.value.path === '/'
  )
})

const resetAnalytics = () => {
  if (analyticsRoutes.value) {
    router.go()
  }
}
</script>
<style lang="scss">
.menu li a:hover,
.btn-candy,
.btn-candy svg {
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.9);
}
.menu li a:hover {
  @apply text-primary;
}
.menu li a svg {
  fill: oklch(0.848664 0.003409 285.064);
}
.menu li a:hover svg {
  @apply fill-primary;
}
</style>
