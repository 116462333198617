import { createRouter, createWebHistory } from 'vue-router'

import FourOhFour from '@/views/404.vue'
import Agents from '@/views/Agents.vue'
import Campaign from '@/views/Campaign.vue'
import Campaigns from '@/views/Campaigns.vue'
import Conversations from '@/views/Conversations.vue'
import Dashboard from '@/views/Dashboard.vue'
import Home from '@/views/Home.vue'
import System from '@/views/System.vue'
import TenantDetails from '@/views/TenantDetails.vue'
import Tenants from '@/views/Tenants.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'Home', component: () => import('@/views/Dashboard.vue') },
    {
      path: '/analytics',
      name: 'Analytics',
      component: () => import('@/views/Dashboard.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/system',
      name: 'System',
      component: () => import('@/views/System.vue'),
    },
    {
      path: '/campaigns/:campaignId/conversation/:id',
      name: 'Conversation',
      component: () => import('@/views/Conversation.vue'),
    },
    {
      path: '/conversations',
      name: 'Conversations',
      component: () => import('@/views/Conversations.vue'),
    },
    {
      path: '/tenants',
      name: 'Tenants',
      component: () => import('@/views/Tenants.vue'),
    },
    {
      path: '/tenants/:id/edit',
      name: 'TenantEdit',
      component: () => import('@/views/TenantDetails.vue'),
    },
    {
      path: '/campaigns',
      name: 'Campaigns',
      component: () => import('@/views/Campaigns.vue'),
    },
    {
      path: '/campaigns/:id/details',
      name: 'CampaignDetailsView',
      component: () => import('@/views/Campaign.vue'),
    },
    {
      path: '/agents',
      name: 'TenantAgents',
      component: () => import('@/views/Agents.vue'),
    },
    {
      path: '/404',
      name: 'FourOhFour',
      component: () => import('@/views/404.vue'),
    },
  ],
})

export default router
